<template>
  <div>
    <div class="footer">
      <p class="footer_1" style="margin-top:0;">Copyright © 2018 DOIT | 北京世纪百易网络有限公司 版权所有 </p>
      <p class="footer_2"> 京ICP备12007866号-14 电信业务审批 [2009]字第572号</p>
      <p class="footer_3"><img src="../../assets/police.png"><a style="text-decoration:none;color:#989898;" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502032764 ">京公网安备 11010502032764号</a></p>
    </div>
 </div>
</template>

<script>
export default {
  name: 'footerIndex',
  data () {
    return {}
  },
  props: {},
  components: {},
  mounted () {},
  methods: {}
}
</script>

<style scoped lang='less'>
.footer {
  padding: 1.1% 10%;
  width: 100%;
  font-size: 14px;
  color: #989898;
  line-height: 1.3em;
  position: fixed;
  left: 0;
  bottom: 0;
  background: #222222;
  clear: both;
  overflow: hidden;
}
.footer .footer_1, .footer .footer_3 {
  text-align: left;
}
.footer p {
  width: 50%;
  float: left;
  margin: 0;
}
.footer .footer_2 {
  text-align: right;
}
img {
  vertical-align: middle;
}
</style>
