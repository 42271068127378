<template>
  <div class="nav">
    <div class="nav-top">
      <div class="logo">
        <img src="../../assets/doitlogo.png" alt="">
      </div>
      <div class="nav-right">
        <div><a href="https://www.doit.com.cn">资讯</a></div>
        <div @click="link">知识</div>
        <div @click="toLogin()" class="dl">登录</div>
        <div class="register" @click="toRegister()">注册</div>
      </div>
      <!-- <div>sss</div> -->
    </div>
  </div>
</template>

<script>
import "./nav.css"
import { getCookie } from '../../api/method'
export default {
  name: 'NavIndex',
  data () {
    return {
      url: `http://edu.doit.com.cn?toKen=${encodeURIComponent(getCookie('accessToken'))}`
    }
  },
  props: {},
  components: {},
  mounted () {},
  methods: {
    link() {
      console.log(1)
    },
    toRegister() {
      this.$router.push('/register')
    },
    toLogin() {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped lang='less'>
</style>
