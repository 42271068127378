<template>
  <div>
    <Nav />
    <div class="forget-body">
      <div class="forget-form">
        <div class="forget-top">
          <img src="../../assets/01.png" alt="">
          <div>重置密码</div>
        </div>
        <div class="forget-bottom">
          <div class="forget-phone">
            <div class="title-name" >手机号</div>
            <input v-model="phone" type="text" class="phone-forget" placeholder="请输入手机号码">
            <div class="sendbtn" @click="resetSend()">{{num==30?codeName:('('+num+')重新发送')}}</div>
          </div>
          <div class="forget-number">
            <div class="title-name">验证码</div>
            <input v-model="code" type="text" class="number-forget"  placeholder="请输入验证码">
          </div>
          <div class="nextbtn" @click="nextTo()">下一步</div>
        </div>
      </div>
    </div>
    <Footer />
 </div>
</template>

<script>
import Nav from '@/views/nav/Nav.vue'
import Footer from '@/views/footer/Footer.vue'
import './forget.css'
export default {
  name: '',
  data () {
    return {
      //重置数据
      phone: '',
      code: '',
      //发送验证码
      codeName: '发送验证码',
      num: 30,
      timer: null,
      isSend: true
    }
  },
  props: {},
  components: {
    Nav,
    Footer,
  },
  mounted () {},
  methods: {
    nextTo() {
      this.$router.push('/resetPassword')
    },
    resetSend(){
      if(this.isSend == true && this.phone != '') {
        this.isSend = false
        console.log(this.isSend)
        
         console.log('1111')
      
        if(this.num == 30) {
          this.timer = setInterval(() => {
            if(this.num > 0){
              this.num--
            }else {
              clearInterval(this.timer)
              this.timer = null
              this.num = 30
              this.isSend =true
            }
          },1000)
        }
      }
      else {
        this.$message({
              message: '请输入手机号',
              type: 'warning'
            })
      }
     
    }
  }
}
</script>

<style scoped lang='less'>
</style>
